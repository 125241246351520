<template>
  <div class="myLesson">
    <mt-navbar v-model="selected">
      <mt-tab-item id="1">
        <span @click="loadMoreAll">全部</span>
      </mt-tab-item>
      <mt-tab-item id="2">
        <span @click="loadMoreNo">未付款</span>
      </mt-tab-item>
      <mt-tab-item id="3">
        <span @click="loadMoreHas">已付款</span>
      </mt-tab-item>
    </mt-navbar>

    <!-- tab-container -->
    <mt-tab-container v-model="selected">
      <mt-tab-container-item id="1">
        <div v-if="lessonListAll.length > 0">
          <ul class="lesson-list">
            <li class="container" v-for="(item, index) in lessonListAll" :key="index">
              <div class="list">
                <router-link
                  :to="item.isoffline == 1 ? `/lesson/orderDetail/${item.order_id}` : `/lesson/onlineDetail/${item.lessonid}`"
                >
                  <h4 class="ell">{{ item.bookname }}</h4>
                  <div class="top">
                    <img v-lazy="item.images" />
                    <div>
                      <p>订单编号：{{ item.order_id }}</p>
                      <p>
                        订单状态：
                        <span class="gold-num">{{ item.status_name }}</span>
                      </p>
                      <p>下单时间：{{ item.createTime }}</p>
                    </div>
                  </div>
                </router-link>
                <div class="price">
                  <span>课程价格：{{ item.marketprice }}</span>
                  <span>
                    订单总额：
                    <em class="gold-num">{{ item.price }}</em>
                  </span>
                </div>
                <div class="btn">
                  <button
                    v-show="item.status == 0"
                    class="gold"
                    @click="btnMethods('pay', item.order_id)"
                  >去付款</button>
                  <button
                    v-show="item.status == 0 || item.status == -1"
                    @click="btnMethods('del', item.order_id)"
                  >删除订单</button>
                  <button
                    v-show="item.status == 0"
                    @click="btnMethods('cancel', item.order_id)"
                  >取消订单</button>
                </div>
              </div>
            </li>
          </ul>
          <Loading :loaded="loadendAll" :loading="loadingAll"></Loading>
        </div>
        <div v-else class="emptyData" v-show="initLoading">
          <img src="@assets/images/emptyData.png" />
          <p>暂无数据</p>
        </div>
      </mt-tab-container-item>

      <mt-tab-container-item id="2">
        <div v-if="lessonListNo.length > 0">
          <ul class="lesson-list">
            <li class="container" v-for="(item, index) in lessonListNo" :key="index">
              <div class="list">
                <router-link
                  :to="item.isoffline == 1 ? `/lesson/orderDetail/${item.order_id}` : `/lesson/onlineDetail/${item.lessonid}`"
                >
                  <h4 class="ell">{{ item.bookname }}</h4>
                  <div class="top">
                    <img v-lazy="item.images" />
                    <div>
                      <p>订单编号：{{ item.order_id }}</p>
                      <p>
                        订单状态：
                        <span class="gold-num">{{ item.status_name }}</span>
                      </p>
                      <p>下单时间：{{ item.createTime }}</p>
                    </div>
                  </div>
                </router-link>
                <div class="price">
                  <span>课程价格：{{ item.price }}</span>
                  <span>
                    订单总额：
                    <em class="gold-num">{{ item.price }}</em>
                  </span>
                </div>
                <div class="btn">
                  <button
                    v-show="item.status == 0"
                    class="gold"
                    @click="btnMethods('pay', item.order_id)"
                  >去付款</button>
                  <button
                    v-show="item.status == 0 || item.status == -1"
                    @click="btnMethods('del', item.order_id)"
                  >删除订单</button>
                  <button
                    v-show="item.status == 0"
                    @click="btnMethods('cancel', item.order_id)"
                  >取消订单</button>
                </div>
              </div>
            </li>
            <Loading :loaded="loadendNo" :loading="loadingNo"></Loading>
          </ul>
        </div>
        <div v-else class="emptyData">
          <img src="@assets/images/emptyData.png" />
          <p>暂无数据</p>
        </div>
      </mt-tab-container-item>

      <mt-tab-container-item id="3">
        <div v-if="lessonListHas.length > 0">
          <ul class="lesson-list">
            <li class="container" v-for="(item, index) in lessonListHas" :key="index">
              <div class="list">
                <router-link
                  :to="item.isoffline == 1 ? `/lesson/orderDetail/${item.order_id}` : `/lesson/onlineDetail/${item.lessonid}`"
                >
                  <h4 class="ell">{{ item.bookname }}</h4>
                  <div class="top">
                    <img v-lazy="item.images" />
                    <div>
                      <p>订单编号：{{ item.order_id }}</p>
                      <p>
                        订单状态：
                        <span class="gold-num">{{ item.status_name }}</span>
                      </p>
                      <p>下单时间：{{ item.createTime }}</p>
                    </div>
                  </div>
                </router-link>
                <div class="price">
                  <span>课程价格：{{ item.price }}</span>
                  <span>
                    订单总额：
                    <em class="gold-num">{{ item.price }}</em>
                  </span>
                </div>
                <div class="btn">
                  <button
                    v-show="item.status == 0 || item.status == -1"
                    @click="btnMethods('del', item.order_id)"
                  >删除订单</button>
                </div>
              </div>
            </li>
          </ul>
          <Loading :loaded="loadendHas" :loading="loadingHas"></Loading>
        </div>
        <div v-else class="emptyData">
          <img src="@assets/images/emptyData.png" />
          <p>暂无数据</p>
        </div>
      </mt-tab-container-item>
    </mt-tab-container>
  </div>
</template>

<script>
import { orderList, orderCancel, orderDel } from "@api/public";
export default {
  data() {
    return {
      selected: "1",
      limit: 10,
      pageAll: 1,
      pageNo: 1,
      pageHas: 1,
      switch: 0,
      lessonListAll: [],
      lessonListNo: [],
      lessonListHas: [],
      loadingAll: false,
      loadendAll: false,
      loadingNo: false,
      loadendNo: false,
      loadingHas: false,
      loadendHas: false,
      initLoading: false
    };
  },
  mounted() {
    this.loadMoreAll();
    this.loadMoreNo();
    this.loadMoreHas();
    if (sessionStorage.getItem("myLessonIndex")) {
      this.selected = sessionStorage.getItem("myLessonIndex");
    }
  },
  watch: {
    selected(n) {
      sessionStorage.setItem("myLessonIndex", n);
    },
  },
  methods: {
    // 获取列表全部数据
    loadMoreAll() {
      let that = this;

      if (that.loadingAll) return; //阻止下次请求（false可以进行请求）；
      if (that.loadendAll) return; //阻止结束当前请求（false可以进行请求）；
      that.loadingAll = true;
      orderList(0, that.limit, that.pageAll).then((res) => {
        that.loadingAll = false;
        //apply();js将一个数组插入另一个数组;
        that.lessonListAll.push.apply(that.lessonListAll, res.data);
        that.loadendAll = res.data.length < that.limit; //判断所有数据是否加载完成；
        that.pageAll = that.pageAll + 1;
        that.initLoading = true;
      });
    },

    // 获取列表未付款数据
    loadMoreNo() {
      let that = this;

      if (that.loadingNo) return; //阻止下次请求（false可以进行请求）；
      if (that.loadendNo) return; //阻止结束当前请求（false可以进行请求）；
      that.loadingNo = true;
      orderList(1, that.limit, that.pageNo).then((res) => {
        that.loadingNo = false;
        //apply();js将一个数组插入另一个数组;
        that.lessonListNo.push.apply(that.lessonListNo, res.data);
        that.loadendNo = res.data.length < that.limit; //判断所有数据是否加载完成；
        that.pageNo = that.pageNo + 1;
      });
    },

    // 获取列表已付款数据
    loadMoreHas() {
      let that = this;

      if (that.loadingHas) return; //阻止下次请求（false可以进行请求）；
      if (that.loadendHas) return; //阻止结束当前请求（false可以进行请求）；
      that.loadingHas = true;
      orderList(2, that.limit, that.pageHas).then((res) => {
        that.loadingHas = false;
        //apply();js将一个数组插入另一个数组;
        that.lessonListHas.push.apply(that.lessonListHas, res.data);
        that.loadendHas = res.data.length < that.limit; //判断所有数据是否加载完成；
        that.pageHas = that.pageHas + 1;
      });
    },

    // 订单操作
    btnMethods(status, order_id) {
      let that = this;
      switch (status) {
        case "pay":
          that.$router.push({
            path: "/lesson/pay",
            query: { order_id: order_id },
          });
          break;
        case "del":
          orderDel(order_id)
            .then((res) => {
              that.$dialog.success(res.msg);
              that.lessonListAll.some(item => {
                if(item.order_id === order_id) {
                  that.lessonListAll.splice(item, 1);
                }
              })
              that.lessonListNo.some(item => {
                if(item.order_id === order_id) {
                  that.lessonListNo.splice(item, 1);
                }
              })
              that.lessonListHas.some(item => {
                if(item.order_id === order_id) {
                  that.lessonListHas.splice(item, 1);
                }
              })
            })
            .catch((err) => {
              that.$dialog.error(err.msg);
            });
          break;
        case "cancel":
          orderCancel(order_id)
            .then((res) => {
              that.$dialog.success(res.msg);
               that.lessonListAll.some((item, i) => {
                if(item.order_id === order_id) {
                  that.$set(that.lessonListAll[i], 'status', -1);
                }
              })
              that.lessonListNo.some((item, i) => {
                if(item.order_id === order_id) {
                  that.$set(that.lessonListNo[i], 'status', -1);
                }
              })
            })
            .catch((err) => {
              that.$dialog.error(err.msg);
            });
          break;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.myLesson {
  padding-bottom: 1rem;
  .container {
    background: white;
    width: 7.1rem;
    margin: 0.2rem 0.2rem 0;
    border-radius: 0.12rem;
    .list {
      width: 100%;
      h4 {
        font-size: 0.28rem;
        padding: 0.2rem 0 0.1rem 0.1rem;
      }
      .top {
        display: flex;
        justify-content: space-between;
        border-bottom: 1px solid #ccc;
        img {
          width: 2.6rem;
          height: 1.6rem;
          padding: 0.1rem;
        }
        div {
          display: flex;
          flex-flow: column;
          justify-content: space-between;
          padding: 0.1rem;
          width: 4.5rem;
          p {
            font-size: 0.26rem;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            color: #656565;
          }
        }
      }
      .price {
        height: 0.4rem;
        margin-top: 0.06rem;
        span {
          font: 0.26rem/0.4rem "microsoft yahei";
          display: inline-block;
          width: 3rem;
          padding-left: 0.1rem;
        }
      }
      .btn {
        width: 100%;
        min-height: 0.2rem;
        overflow: hidden;
        button {
          float: right;
          height: 0.5rem;
          font-size: 0.24rem;
          border: 0.02rem solid #a9a9a9;
          margin: 0.15rem 0.1rem;
          border-radius: 0.24rem;
          padding: 0 0.2rem;
        }
        .gold {
          border: none;
          background: black;
          color: white;
        }
      }
    }
  }
}
</style>